<template>
  <div class="content reset-btn-page">
    <div class="start-left">
      <img class="start-top-img" src="../assets/images/start-top.png">
      <div class="img-swiper">
        <img v-if="icon" style="width: 100%;height: 100%" :src="icon">
        <img v-if="!icon" style="width: 100%;height: 100%" :src="sty">
      </div>
      <div class="edition-number">
        <img style="width: 335px;" src="../assets/images/logo-icon@2x.png">

      </div>
    </div>
    <div class="start-right">
      <el-form label-width="100px" size="medium" class="small-from" label-position="top">
        <el-form-item label="图片设置">
          <div style="display: flex;align-items: center">
            <SingleMediaWall ratio="416*152" addText="" v-model="icon" :width="60" :height="60" :borderRadius="4">
            </SingleMediaWall>
          </div>
          <div style="width:200px;font-size: 12px;color: #C0C4CC;">尺寸推荐 416*152 </div>
        </el-form-item>
      </el-form>
    </div>
    <FixedActionBar>
      <el-button type="primary" @click="saveConfig">保存</el-button>
      <!-- <el-button>取消</el-button> -->
    </FixedActionBar>
  </div>
</template>

<script>
import SingleMediaWall from "../../common/components/SingleMediaWall";
import FixedActionBar from "@/base/layout/FixedActionBar";
import { getCycLoginPage, setCycLoginPage } from "../api/cyc-login-page";
export default {
  components: { SingleMediaWall, FixedActionBar },
  data() {
    return {
      icon: "",
      sty:
        window.serverConfig.VUE_APP_ADMINURL +
        "/q64KaQle/admin/images/20210121/b4d4ade7aa643abb19732396dffa59e7.png",
      status: 1,
    };
  },
  created() {
    getCycLoginPage()
      .then((res) => {
        this.icon = res.data.image;
      })
      .catch((err) => {});
  },
  methods: {
    saveConfig() {
      if (this.icon) {
        var i = this.icon;
      } else {
        var i = this.sty;
      }
      setCycLoginPage({ config: { image: i } })
        .then((res) => {
          this.$message.success("保存成功");
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  .start-left {
    width: 375px;
    height: 668px;
    border: 1px solid #dcdfe6;
    background-color: #fff;
    flex-shrink: 0;
    position: relative;
    .start-top-img {
      width: 375px;
      height: 65px;
    }
    .img-swiper {
      width: 208px;
      height: 76px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 172px;
    }
    .edition-number {
      display: flex;
      justify-content: center;
      margin-top: 285px;
    }
  }
  .start-right {
    margin-left: 48px;

    .img-list {
      width: 278px;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
