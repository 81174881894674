import api from "../../../base/utils/request";

export const getCycLoginPage = data => {
    return api({
        url: '/admin/cyc/Settings/getCycLoginPageConfig',
        method: 'post',
        data
    })
}

export const setCycLoginPage = data => {
    return api({
        url: '/admin/cyc/Settings/setCycLoginPageConfig',
        method: 'post',
        data
    })
}
